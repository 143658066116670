import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import { CustomFormFieldCreateOrUpdateDto } from "@/api/appService";
import { IValidate } from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";

export default class MobilePhoneValidator
  extends AbstractValidator
  implements IValidate
{
  constructor(expression: string) {
    super(expression);
  }

  displayName(): string {
    return "手机号";
  }

  isMatch(expression: string): boolean {
    return expression === this.name();
  }

  properties(): ValidatorProperty[] {
    return [];
  }

  validate(value: any): string {
    if (typeof value === "string" && value.length) {
      if (/^(1[\d]{10})$/.test(value)) {
        return "";
      } else {
        return "不是有效的手机号";
      }
    } else {
      return "";
    }
  }

  name(): string {
    return "mobilePhone";
  }

  toString(): string {
    return this.name();
  }
}
